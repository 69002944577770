import {
  getThemeById,
  updateTheme,
  applyTheme,
  ThemeConfigFile
} from '@api/Themes'
import React, { createContext, useContext, useState } from 'react'

interface Theme {
  name: string
  resources: Array<{ id: string; slug: string; name: string; theme: unknown }>
  thumbnail: string
  config: ThemeConfigFile
}

interface ThemesContextData {
  currentTheme: Theme | null
  loadTheme: (id: string) => Promise<void>
  updateCurrentTheme: (id: string, data: Theme) => Promise<void>
  applyThemeToEntity: (entityId: string) => Promise<void>
  isLoading: boolean
}

const ThemesContext = createContext<ThemesContextData | undefined>(undefined)

export const ThemesProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [currentTheme, setCurrentTheme] = useState<Theme | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const loadTheme = async (id: string) => {
    try {
      setIsLoading(true)
      const response = await getThemeById(id)
      setCurrentTheme(response.data)
      setIsLoading(false)
    } catch (error) {
      console.error('Erro ao carregar tema:', error)
      setIsLoading(false)
    }
  }

  const updateCurrentTheme = async (id: string, data: Theme) => {
    try {
      await updateTheme(id, data)
      loadTheme(id)
    } catch (error) {
      console.error('Erro ao atualizar o tema:', error)
    }
  }

  const applyThemeToEntity = async (entityId: string) => {
    if (!currentTheme) {
      console.error('Nenhum tema selecionado para aplicar')
      return
    }
    try {
      await applyTheme(currentTheme.name, entityId)
    } catch (error) {
      console.error('Erro ao aplicar o tema à entidade:', error)
    }
  }

  return (
    <ThemesContext.Provider
      value={{
        currentTheme,
        isLoading,
        loadTheme,
        updateCurrentTheme,
        applyThemeToEntity
      }}
    >
      {children}
    </ThemesContext.Provider>
  )
}

export const useThemeContext = () => {
  const context = useContext(ThemesContext)
  if (!context) {
    throw new Error('useTheme deve ser usado dentro de um ThemesProvider')
  }
  return context
}
