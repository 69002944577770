import { createGlobalStyle, css, keyframes } from 'styled-components'
import 'react-toastify/dist/ReactToastify.css'

export const fadeIn = keyframes`
  from {
    filter: opacity(0);
  }
  to {
    filter: opacity(1);
  }
`

export const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translate(0, -10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0px);
  }
`

export const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0px);
  }
`

export const slideLeft = keyframes`
  from {
    opacity: 0;
    transform: translate(10px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0);
  }
`

export const fadesAwayslideLeft = keyframes`
  from {
    opacity: 1;
    transform: translate(0px, 0);
  }
  to {
    opacity: 0;
    transform: translate(10px, 0);
  }
`

export const slideRight = keyframes`
  from {
    opacity: 0;
    transform: translate(-10px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0);
  }
`

export const slideRightClose = keyframes`
  from {
    opacity: 1;
    transform: translate(0, 0);
  }
  to {
    opacity: 0;
    transform: translate(10px, 0);
  }
`

export const rotation = keyframes`
  from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(-359deg);
	}
`

const GlobalStyles = createGlobalStyle`
/* Animations var */
:root{
  --animate: 0.4s;
  --animateLow: 2s;
}

/* Animations  */
.fadeIn {
    animation: ${fadeIn} var(--animate) linear 1;
  }
  .slideRightClose {
    animation: ${slideRightClose} var(--animate) linear 1;
  }
  .slideDown {
    animation: ${slideDown} var(--animate) linear 1;
  }
  .slideUp {
    animation: ${slideUp} var(--animate) linear 1;
  }
  .slideLeft {
    animation: ${slideLeft} var(--animate) linear 1;
  }
  .fadesAwayslideLeft{
    animation: ${fadesAwayslideLeft} var(--animate) linear 1;
  }
  .slideRight {
    animation: ${slideRight} var(--animate) linear 1;
  }
  .rotationAnimation {
    animation: ${rotation} var(--animateLow) ease-in-out infinite;
  }

button,select{
  cursor: pointer;
}

  ${({ theme }) => css`
    html {
      font-size: 62.5%;
    }
    body,
    html {
      max-width: 100%;
      overflow-x: hidden;
    }

    *,
    *::before,
    *::after {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    body,
    button {
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.medium};
      background: ${theme.colors.gray100};
    }

    .realize-global-regular {
      font-weight: ${theme.font.normal};
    }

    .realize-global-bold {
      font-weight: 900;
    }

    .realize-global-semibold {
      font-weight: ${theme.font.bold};
    }

    .realize-global-body1 {
      font-weight: ${theme.font.normal};
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.gray900};
    }

    .realize-global-body2 {
      font-weight: ${theme.font.normal};
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.gray900};
    }

    .realize-global-body3 {
      font-weight: ${theme.font.normal};
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.gray400};
    }

    .realize-global-subtitle1 {
      font-weight: ${theme.font.bold};
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.gray900};
    }

    .realize-global-subtitle2 {
      font-weight: ${theme.font.bold};
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.gray900};
    }

    .realize-global-title {
      margin-top: ${theme.spacings.large};
    }

    h1 {
      color: ${theme.colors.gray900};
      font-size: 9.6rem;
      letter-spacing: 0.0075em;
    }

    h2 {
      color: ${theme.colors.gray900};
      font-size: 6rem;
      letter-spacing: 0.0075em;
    }

    h3 {
      color: ${theme.colors.gray900};
      font-size: 4.8rem;
      letter-spacing: 0.0075em;
    }

    h4 {
      color: ${theme.colors.gray900};
      font-size: 3.2rem;
      letter-spacing: 0.0075em;
    }

    h5,
    .realize-global-headline5 {
      color: ${theme.colors.gray900};
      font-size: 2.4rem;
      letter-spacing: 0.0075em;
    }

    h6 {
      color: ${theme.colors.gray900};
      font-size: ${theme.font.sizes.xlarge};
      letter-spacing: 0.0075em;
    }

    .realize-subtitle-1 {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }

    p {
      color: ${theme.colors.darkGray};
      font-weight: 400;
      letter-spacing: 0.0075em;
    }

    // TINYMCE
    body > .tox {
      z-index: 10;
    }
    body > .tox + .tox,
    body > .tox + div .tox body > .tox + div + div .tox {
      z-index: 50;
    }
    form .tox span {
      align-self: auto;
    }
    .tox.tox-tinymce.tox-tinymce-inline.tox-tinymce--toolbar-sticky-off {
      z-index: 50;
    }

    // SAFARI ERROR
    input::-webkit-input-placeholder {
      line-height: normal !important;
    }

    // No Select text hightlight

    .noselect {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }

    .custom-scrollbar {
      overflow-y: auto;
    }

    .custom-scrollbar::-webkit-scrollbar {
      width: 6px;
    }

    .custom-scrollbar::-webkit-scrollbar-track {
      background: #414141;
    }

    .custom-scrollbar::-webkit-scrollbar-thumb {
      background: #aaaaaa;
      border-radius: 10px;
    }

    /* MATHTYPE */
    .wrs_modal_dialogContainer.wrs_modal_desktop.wrs_stack {
      display: none;
    }
  `}
`

export default GlobalStyles
