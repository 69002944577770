import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ colorTitle?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  min-height: 90vh;

  ${({ colorTitle }) =>
    colorTitle &&
    css`
      h6 {
        color: ${colorTitle};
      }
    `}
`
