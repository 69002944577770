/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentType, lazy, LazyExoticComponent, ReactNode } from 'react'
import Loading from '@components/Templates/Loading'
import InternalLoading from '@components/Templates/Loading/InternalLoading'

export interface IRoute {
  path: string
  exact: boolean
  fallback: NonNullable<ReactNode> | null
  component?: LazyExoticComponent<ComponentType<any>>
  template?: LazyExoticComponent<ComponentType<any>>
  routes?: IRoute[]
  redirect?: string
  private?: boolean
  permissions?: string[]
  permissionRedirectUrl?: string
}

export const routes: IRoute[] = [
  {
    path: '/login',
    component: lazy(() => import('../pages/Login')),
    exact: false,
    private: false,
    fallback: <Loading />
  },
  {
    path: '/forgot-password',
    component: lazy(() => import('../pages/ForgotPassword')),
    exact: false,
    private: false,
    fallback: <Loading />
  },
  {
    path: '/password',
    component: lazy(() => import('../pages/RecoverPassword')),
    exact: false,
    private: false,
    fallback: <Loading />
  },
  {
    path: '/editor/unit/:unitId',
    exact: false,
    private: true,
    component: lazy(() => import('../pages/Editor')),
    fallback: <Loading />
  },
  {
    path: '/share/:unitId',
    exact: false,
    private: false,
    component: lazy(() => import('../pages/Share')),
    fallback: <Loading />
  },
  {
    path: '/themes/:themeId/customizer',
    exact: false,
    component: lazy(() => import('../components/Templates/Customizer')),
    fallback: <Loading />,
    routes: [
      {
        path: '/themes/:themeId/customizer',
        component: lazy(() => import('../pages/Themes/ThemeCustomizer')),
        exact: true,
        private: true,
        fallback: <InternalLoading />
      },
      {
        path: '/themes/:themeId/customizer/identity',
        component: lazy(() => import('../pages/Themes/IdentityEditor')),
        exact: true,
        private: true,
        fallback: <InternalLoading />
      },
      {
        path: '/themes/:themeId/customizer/layout',
        component: lazy(() => import('../pages/Themes/LayoutEditor')),
        exact: true,
        private: true,
        fallback: <InternalLoading />
      },
      {
        path: '/themes/:themeId/customizer/resources',
        component: lazy(() => import('../pages/Themes/ResourceList')),
        exact: true,
        private: true,
        fallback: <InternalLoading />
      },
      {
        path: '/themes/:themeId/customizer/resources/editor',
        component: lazy(() => import('../pages/Themes/ResourceEditor')),
        exact: true,
        private: true,
        fallback: <InternalLoading />
      }
    ]
  },
  {
    path: '/',
    exact: false,
    component: lazy(() => import('../components/Templates/Main')),
    fallback: <Loading />,
    routes: [
      {
        path: '/config',
        component: lazy(() => import('../pages/Config')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['edit_settings']
      },
      {
        path: '/templates',
        component: lazy(() => import('../pages/Templates')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['create_templates']
      },
      {
        path: '/templates/add',
        component: lazy(() => import('../pages/Templates/AddTemplate')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['create_templates']
      },
      {
        path: '/templates/edit/:id',
        component: lazy(() => import('../pages/Templates/EditTemplate')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['edit_templates']
      },
      {
        path: '/dashboard',
        component: lazy(() => import('../pages/Dashboard')),
        exact: true,
        private: true,
        fallback: <InternalLoading />
      },
      {
        path: '/themes',
        component: lazy(() => import('../pages/Themes')),
        exact: true,
        private: true,
        fallback: <InternalLoading />
      },
      {
        path: '/account',
        component: lazy(() => import('../pages/Account')),
        exact: true,
        private: true,
        fallback: <InternalLoading />
      },
      {
        path: '/dashboard/:subjectId/units',
        component: lazy(() => import('../pages/ContentistSubject')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['view_content_interface']
      },
      {
        path: '/dashboard/:subjectId/units/:unitId/management',
        component: lazy(() => import('../pages/ContentistUnit')),
        exact: true,
        private: true,
        fallback: <InternalLoading />
      },
      {
        path: '/dashboard/:projectId/overview',
        component: lazy(() => import('../pages/Overview')),
        exact: true,
        private: true,
        fallback: <InternalLoading />
      },
      {
        path: '/procedure',
        component: lazy(() => import('../pages/Procedure')),
        exact: false,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['overview_procedures']
      },
      {
        path: '/user/add',
        component: lazy(() => import('../pages/User/AddUser')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: [
          'create_users_with_higher_level',
          'create_users_with_same_level'
        ]
      },
      {
        path: '/user/archived',
        component: lazy(() => import('../pages/User/ArchivedUser')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: [
          'view_users_with_same_level',
          'view_users_with_higher_level'
        ]
      },
      {
        path: '/user/view/:id',
        component: lazy(() => import('../pages/User/ViewUser')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: [
          'view_users_with_same_level',
          'view_users_with_higher_level'
        ]
      },
      {
        path: '/user/edit/:id',
        component: lazy(() => import('../pages/User/EditUser')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: [
          'edit_users_with_higher_level',
          'edit_users_with_same_level'
        ]
      },
      {
        path: '/user',
        component: lazy(() => import('../pages/User')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: [
          'view_users_with_same_level',
          'view_users_with_higher_level'
        ]
      },
      {
        path: '/group',
        component: lazy(() => import('../pages/Group/index')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['view_groups']
      },
      {
        path: '/group/add',
        component: lazy(() => import('../pages/Group/AddGroup')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['create_groups']
      },
      {
        path: '/group/edit/:groupId',
        component: lazy(() => import('../pages/Group/EditGroup')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['edit_groups']
      },
      {
        path: '/group/view/:groupId',
        component: lazy(() => import('../pages/Group/ViewGroup')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['view_groups']
      },
      {
        path: '/group/archived',
        component: lazy(() => import('../pages/Group/ArchivedGroup')),
        exact: false,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['view_groups']
      },
      {
        path: '/project',
        component: lazy(() => import('../pages/Project/ListProject')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['access_organization']
      },
      {
        path: '/project/add',
        component: lazy(() => import('../pages/Project/AddProject')),
        exact: false,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['create_projects']
      },
      {
        path: '/project/archived',
        component: lazy(() => import('../pages/Project/ArchivedProject')),
        exact: false,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['access_organization']
      },
      {
        path: '/project/edit/:id',
        component: lazy(() => import('../pages/Project/EditProject')),
        exact: false,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['edit_projects']
      },
      {
        path: '/project/personalize/:id',
        component: lazy(() => import('../pages/Project/PersonalizeProject')),
        exact: false,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['edit_extras']
      },
      {
        path: '/project/:projectId/areas/:areaId/subjects/archived',
        component: lazy(() => import('../pages/Subjects/ArchiveSubjects')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['access_organization']
      },
      {
        path: '/project/:projectId/areas/:areaId/subjects',
        component: lazy(() => import('../pages/Subjects/ListSubjects')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['access_organization']
      },
      {
        path: '/project/:projectId/areas/:areaId/subjects/add',
        component: lazy(() => import('../pages/Subjects/AddSubject')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['create_subjects']
      },
      {
        path: '/project/:projectId/areas/:areaId/subjects/edit/:id',
        component: lazy(() => import('../pages/Subjects/EditSubject')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['edit_subjects']
      },
      {
        path: '/project/:projectId/areas/:areaId/subjects/personalize/:id',
        component: lazy(() => import('../pages/Subjects/PersonalizeSubject')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['edit_extras']
      },
      {
        path:
          '/project/:projectId/areas/:areaId/subjects/:subjectId/units/archived',
        component: lazy(() => import('../pages/Units/ArchivedUnits')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['access_organization']
      },
      {
        path: '/project/:projectId/areas/:areaId/subjects/:id/units',
        component: lazy(() => import('../pages/Units/ListUnits')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['access_organization']
      },
      {
        path: '/project/:projectId/areas/:areaId/subjects/:subjectId/units/add',
        component: lazy(() => import('../pages/Units/AddUnit')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['create_units']
      },
      {
        path:
          '/project/:projectId/areas/:areaId/subjects/:subjectId/units/edit/:unitId',
        component: lazy(() => import('../pages/Units/EditUnit')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['edit_units']
      },
      {
        path:
          '/project/:projectId/areas/:areaId/subjects/:subjectId/units/personalize/:unitId',
        component: lazy(() => import('../pages/Units/PersonalizeUnit')),
        exact: true,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['edit_extras']
      },
      {
        path:
          '/project/:projectId/areas/:areaId/subjects/:subjectId/units/:unitId/management',
        component: lazy(() => import('../pages/Management/UnitManagement')),
        exact: true,
        private: true,
        fallback: <InternalLoading />
      },
      {
        path: '/unit/:unitId',
        component: lazy(() => import('../pages/Management')),
        exact: true,
        private: true,
        fallback: <InternalLoading />
      },
      {
        path:
          '/project/:projectId/areas/:areaId/subjects/:subjectId/units/:unitId',
        component: lazy(() => import('../pages/Management')),
        exact: true,
        private: true,
        fallback: <InternalLoading />
      },
      {
        path: '/project/:projectId/areas/add',
        component: lazy(() => import('../pages/Areas/AddNewArea')),
        exact: false,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['create_areas']
      },
      {
        path: '/project/:projectId/areas/edit/:areaId',
        component: lazy(() => import('../pages/Areas/EditArea')),
        exact: false,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['edit_areas']
      },
      {
        path: '/project/:projectId/areas/personalize/:areaId',
        component: lazy(() => import('../pages/Areas/PersonalizeArea')),
        exact: false,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['edit_extras']
      },
      {
        path: '/project/:projectId/areas/archived',
        component: lazy(() => import('../pages/Areas/ArchivedArea')),
        exact: false,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['access_organization']
      },
      {
        path: '/project/:projectId/areas',
        component: lazy(() => import('../pages/Areas')),
        exact: false,
        private: true,
        fallback: <InternalLoading />,
        permissions: ['access_organization']
      }
    ]
  }
]
